import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import "./style.css"
import "../../App.css"
import Metainsights from "./Metainsights"
import Metamorph from "./Metamorph"
import { Card } from "antd"
import { BookOpen } from "lucide-react"
import TypewriterText from "../Common/TypewriteText"
import weDoCardData from "../../data/weDoCardData"

const HoverCard = ({ icon, title, description }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="relative w-72 h-80 rounded-lg bg-white gradient-button  shadow-lg overflow-hidden cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Card Content */}
      <div className="p-6 h-full flex flex-col items-center justify-center">
        {/* {icon} */}
        <h3 className="text-xl font-bold text-gray-800 ">{title}</h3>
      </div>

      {/* Overlay with Description */}
      <div
        className={`absolute inset-0 bg-blue-900/90 p-6 transition-opacity duration-300 flex items-center justify-center
          ${isHovered ? "opacity-100" : "opacity-0 pointer-events-none"}`}
      >
        <TypewriterText isVisible={isHovered} text={description} />
      </div>
    </div>
  )
}

const Products = () => {
  const navigate = useNavigate()

  return (
    <>
      {/* Part II */}
      <div className="prodBackground">
        <h1
          className="prod-h1"
          id="Service2"
          style={{ textAlign: "left", color: "#ffffff" }}
        >
          What we do!
        </h1>
        <div className="prod">
          {weDoCardData.map((item) => (
            <HoverCard
              description={item.description}
              icon={item.icon}
              key={item.id}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Products
