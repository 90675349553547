import React, { useState } from "react"

const TypewriterText = ({ text, isVisible }) => {
  const [displayText, setDisplayText] = useState("")

  React.useEffect(() => {
    if (isVisible && displayText.length < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText(text.slice(0, displayText.length + 1))
      }, 40)
      return () => clearTimeout(timeout)
    }
    if (!isVisible) {
      setDisplayText("")
    }
  }, [displayText, isVisible, text])

  return <span className="text-white text-md font-semibold">{displayText}</span>
}

export default TypewriterText
