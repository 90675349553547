import { BookOpen, Code2, Rocket, PaintBucket } from "lucide-react"

const ServiceCardData = [
  {
    id: 1,

    title: "Quality, Reliability & Speed",
    description:
      "We fuse masterful craftsmanship with agility, unleashing reliable, scalable innovation",
    icon: <PaintBucket className="w-16 h-20 text-violet-500" />,
  },
  {
    id: 2,
    title: "Data Driven Approach",
    description:
      "We turn raw data into we turn raw data into meaningful insights, allowing businesses to make informed decisions that drive results and measurable impact",
    icon: <Code2 className="w-16 h-20 text-blue-600" />,
  },
  {
    id: 3,
    title: "Innovation Labs",
    description:
      "Our commitment to R&D powers the development of exclusive frameworks, research, and PoCs that fast track value delivery",
    icon: <Rocket className="w-16 h-20 text-green-400" />,
  },
  {
    id: 4,
    title: "Collaborative Approach",
    description:
      "We build long-term relationships with our clients, working together to achieve common goals and addressing unique business challenges.",
    icon: <BookOpen className="w-16 h-20 text-yellow-500" />,
  },
]

export default ServiceCardData
