import React from "react"
import "./style.css"
import "../../App.css"
import { Card } from "antd"

const CaseStudies = () => {
  return (
    <>
      <div className="caseBackground">
        <h1
          className="serv-h1"
          id="Services"
          style={{ textAlign: "left", color: "#ffffff" }}
        >
          Key Metrics We deliver
        </h1>
        <div className="caseserv">
          <Card
            hoverable={true}
            title={
              <>
                <h3
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "1rem",
                  }}
                  className="rainbowText"
                >
                  75%
                </h3>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Efficiency Unleashed
                </p>
              </>
            }
            bordered={true}
            style={{ width: "24%" }}
          >
            <p
              style={{
                marginBottom: "1.4rem",
                height: "7rem",
                overflow: "auto",
              }}
            >
              Reduced development hours for data pipelines
            </p>
            <a href="#">Case Study</a>
          </Card>
          <Card
            hoverable={true}
            title={
              <>
                <h3
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "1rem",
                  }}
                  className="rainbowText"
                >
                  12000
                </h3>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Your Data Governed
                </p>
              </>
            }
            bordered={true}
            style={{ width: "24%" }}
          >
            <p
              style={{
                marginBottom: "1.4rem",
                height: "7rem",
                overflow: "auto",
              }}
            >
              Governance amplified with onboarding of data assets to unified
              governance platform
            </p>
            <a href="#">Case Study</a>
          </Card>
          <Card
            hoverable={true}
            title={
              <>
                <h3
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "1rem",
                  }}
                  className="rainbowText"
                >
                  50%
                </h3>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  AI Efficiency{" "}
                </p>
              </>
            }
            bordered={true}
            style={{ width: "24%" }}
          >
            <p
              style={{
                marginBottom: "1.4rem",
                height: "7rem",
                overflow: "auto",
              }}
            >
              Reduced business user hours by half using Generative AI
            </p>
            <a href="#">Case Study</a>
          </Card>
        </div>
      </div>
    </>
  )
}

export default CaseStudies
