import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./style.css"
import "../../App.css"
import { logo_name } from "../../assets"
import GradientBtn from "../Common/GradientBtn/GradientBtn"
// import { useWindowDimensions } from "../Common/useWindowDimensions";

const Navbar = () => {
  const navigate = useNavigate()
  // const { height, width } = useWindowDimensions();

  const [screenSize, setScreenSize] = useState(getCurrentDimension())
  const [show, setShow] = useState(true)
  const [activeItem, setActiveItem] = useState("Home") // Default active item

  const handleItemClick = (item) => {
    setActiveItem(item) // Set the clicked item as active
  }

  const menuItems = ["Home", "About", "Services", "Products", "Careers"]
  // const [lastScrollY, setLastScrollY] = useState(0);

  const [aboutNav, setAboutNav] = useState(false)

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener("resize", updateDimension)

    // if (typeof window !== "undefined") {
    //   window.addEventListener("scroll", controlNavbar);

    //   // cleanup function
    //   return () => {
    //     window.removeEventListener("scroll", controlNavbar);
    //   };
    // }

    return () => {
      window.removeEventListener("resize", updateDimension)
      console.log("width", screenSize.width)
    }
    // }, [screenSize, lastScrollY]);
  }, [screenSize])

  useEffect(() => {
    let url = window.location.href.split("/")
    let target = url[url.length - 1].toLowerCase()
    let element = document.getElementById(target)
    element && element.scrollIntoView({ behavior: "smooth", block: "start" })
  }, [])

  return (
    <>
      {show && (
        <div className="nav-container">
          <img
            src={logo_name}
            alt={"Logo"}
            className="logo"
            onClick={() => {
              navigate("/")
            }}
          />
          {screenSize.width > 385 && (
            <nav className="nav">
              <ul className="menu">
                {menuItems.map((item) => (
                  <li
                    key={item}
                    className={activeItem === item ? "active" : ""}
                    onClick={() => handleItemClick(item)}
                  >
                    <a href={`#${item}`}>{item}</a>
                  </li>
                ))}

                <a href="#Connect">
                  <GradientBtn
                    // onClick={() => {
                    //   window.scrollTo(#Connect)
                    // }}
                    btnText="Lets Connect"
                  />
                </a>

                {/* <li>Lets Connect</li> */}
              </ul>
              {/*About,Services,Products, Careers, Lets Connect */}
            </nav>
          )}

          {screenSize.width <= 385 && <nav className="nav"></nav>}
        </div>
      )}

      {aboutNav && (
        <div className="nav-container-sec">
          <nav className="nav" style={{ marginLeft: "14rem" }}>
            <a
              href="/"
              onClick={(e) => {
                let about = document.getElementById("About")
                e.preventDefault()
                about &&
                  about.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                window.history.pushState("about", "About", "/About")
              }}
            >
              About Us
            </a>
            <a href="/Metamorph" target="_blank">
              Team
            </a>
            <a href="/Metamorph" target="_blank">
              Clients
            </a>
            <a
              href="/"
              onClick={(e) => {
                let weValue = document.getElementById("WeValue")
                e.preventDefault()
                weValue &&
                  weValue.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                window.history.pushState("weValue", "WeValue", "/WeValue")
              }}
            >
              Why Gleafink
            </a>
            <a href="/#Labs">Gleafink Labs</a>
          </nav>
        </div>
      )}
    </>
  )
}

export default Navbar
