import React from "react"

const GradientBtn = ({ customStyle, onClick, btnText }) => {
  return (
    <button className="gradient-button" style={customStyle} onClick={onClick}>
      {btnText}
    </button>
  )
}

export default GradientBtn
