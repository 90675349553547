import { Input } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"
import emailjs from "@emailjs/browser"

import "./style.css"

const Connect = () => {
  const [status, setStatus] = useState("")
  const [disable, setDisable] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phone: "",
    message: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Here you would typically send the data to your backend
    console.log("Form submitted:", formData)

    try {
      // Replace these with your actual EmailJS service details
      setStatus("starting")
      setDisable(true)
      const result = await emailjs.send(
        "service_0roejxo",
        "template_l1t4sxg",
        {
          from_name: `${formData.firstName} ${formData.lastName}`,
          reply_to: formData.email,
          from_contact_info: formData.phone,
          from_company_name: formData.companyName,
          message: formData.message,
        },
        "ftIFlAjdtzn4nYZjj"
      )

      if (result.text === "OK") {
        setStatus("success")
        setDisable(false)
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          phone: "",
          message: "",
        })
      }
    } catch (error) {
      console.error("Failed to send email:", error)
      setDisable(false)
      setStatus("error")
    }
  }

  return (
    <div className="connect" id="Connect">
      <div className="connect-content" style={{ width: "30%" }}>
        <h1>Begin Your Journey With Us Today</h1>
        <h3>
          Looking for a partner to help accelerate your technology roadmap? Fill
          out the form to start the conversation today.
        </h3>
      </div>
      <div className="connect-content" style={{ width: "50%" }}>
        <form
          className="connect-form"
          name="contact"
          method="POST"
          action="/Connect/?success=true"
          data-netlify="true"
          netlify
        >
          <div className="flex justify-between ">
            <div className="w-[45%]">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium mb-1"
              >
                First Name
              </label>
              <Input
                id="firstName"
                className="bg-gray-200 p-2 border shadow-sm"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Jane"
                disabled={disable}
                required
              />
            </div>
            <div className="w-[45%]">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium mb-1"
              >
                Last Name
              </label>
              <Input
                id="lastName"
                className="bg-gray-200 p-2 border shadow-sm"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="doe"
                disabled={disable}
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">
              Email
            </label>
            <Input
              id="email"
              className="bg-gray-200 p-2 border shadow-sm"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Jane.doe@example.com"
              disabled={disable}
              required
            />
          </div>
          <div>
            <label
              htmlFor="companyName"
              className="block text-sm font-medium mb-1"
            >
              Company Name
            </label>
            <Input
              id="companyName"
              className="bg-gray-200 p-2 border shadow-sm"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Acme Inc."
              disabled={disable}
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium mb-1">
              Phone
            </label>
            <Input
              id="phone"
              className="bg-gray-200 p-2 border shadow-sm"
              name="phone"
              type="tel"
              value={formData.phone}
              disabled={disable}
              onChange={handleChange}
              placeholder="+1 (555) 000-0000"
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium mb-1">
              What would you like to discuss
            </label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              disabled={disable}
              onChange={handleChange}
              placeholder="Your message here..."
              className="h-36 bg-gray-200 p-2 border shadow-sm"
              required
            />
          </div>

          <button className="connect-btn" type="submit" onClick={handleSubmit}>
            {status === "starting"
              ? "Sending.."
              : status === "error"
              ? "Something Went wrong!"
              : "Send Message"}
          </button>
          {/* <a href="mailto:knockers@gleafink.com">mail to </a> */}
        </form>
      </div>
    </div>
  )
}

export default Connect
