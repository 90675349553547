import { BookOpen, Code2, Rocket, PaintBucket } from "lucide-react"

const weDoCardData = [
  {
    id: 1,

    title: "Data & Analytics",
    description:
      "Drive operational excellence, improve customer experiences, and innovate with data and analytics solutions.",
    icon: <PaintBucket className="w-16 h-20 text-violet-500" />,
  },
  {
    id: 2,
    title: "Cloud Transformation",
    description:
      "Build a resilient infrastructure, reduce expenses, and enhance agility through cloud migration, optimization, and FinOps practices.",
    icon: <Code2 className="w-16 h-20 text-blue-600" />,
  },
  {
    id: 3,
    title: "AI/ML",
    description:
      "Speed up innovation, strengthen your advantage, and create real business impact through AI/ML development services.",
    icon: <Rocket className="w-16 h-20 text-green-400" />,
  },
  {
    id: 4,
    title: "Custom Software Engineering",
    description:
      "Design, build, and refine custom software—from solution architecture through core engineering to ongoing product evolution and support.",
    icon: <BookOpen className="w-16 h-20 text-yellow-500" />,
  },
]

export default weDoCardData
