import React, { useState } from "react"
import "./style.css"
import "../../App.css"
import { Button, Card } from "antd"
import { Info } from "lucide-react"
import ServiceCardData from "../../data/ServiceCardData"

const Services = () => {
  return (
    <>
      {/* Part II */}
      <div className="servBackground" id="Services">
        <h1
          className="serv-h1"
          style={{ textAlign: "left", color: "#000", paddingTop: "120px" }}
        >
          Why Us
        </h1>
        <div className="serv">
          {ServiceCardData.map((item) => {
            return (
              <div className="relative rounded-lg shadow-lg bg-white border-gray-200 border w-72 h-80 overflow-hidden group cursor-pointer">
                {/* Main Card Content */}
                <div className="h-[100%] flex flex-col items-center justify-center p-4 space-y-2">
                  {item.icon}
                  <h3 className="text-lg font-semibold text-center pt-4">
                    {item.title}
                  </h3>
                </div>

                {/* Sliding Description Overlay */}
                <div className="absolute inset-0 bg-blue-900/70 p-4 transform transition-transform duration-300 ease-in-out -translate-x-full group-hover:translate-x-0">
                  <div className="h-full flex flex-col justify-between text-white">
                    <p className="text-lg font-semibold">{item.description}</p>
                    <Button
                      variant="outline"
                      className="w-full mt-2 bg-white/10 hover:bg-white/20 text-white border-white/20"
                    >
                      Learn More
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Services
