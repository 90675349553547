import React from "react"
import "../../App.css"
import Footer from "../Footer"
import Navbar from "../Navbar"
import "./style.css"

import Connect from "../Connect"
import CaseStudies from "../CaseStudies"
import GradientBtn from "../Common/GradientBtn/GradientBtn"
import Products from "../Products"
import Services from "../Services"
import { heroRightImage } from "../../assets"

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="home" id="Home">
        <div className="landingContent">
          <div className="landing">
            <div className="home-content">
              <h1>
                Transforming{" "}
                <span style={{ color: "#16a34a", fontWeight: "bold" }}>
                  Data
                </span>{" "}
                into Actionable Insights
              </h1>
              <p>
                <p>
                  A leading data & advanced analytics company. We empower
                  businesses to make data-driven decisions for a smarter future.
                </p>
                <div className="home-btn-line">
                  <a href="#Connect">
                    <GradientBtn
                      customStyle={{ color: "#fff" }}
                      btnText="Get in touch"
                    />
                  </a>
                </div>
              </p>
            </div>
            <img
              src={heroRightImage}
              alt="heroRightImage"
              width={"530px"}
              // style={{ width: "calc(100% - 730px)" }}
            />
          </div>
        </div>

        <div className="combine-bg--container">
          <Services />
          <Products />
        </div>

        <div id="Products" style={{ paddingTop: "100px" }}>
          <h1
            className="serv-h1"
            style={{
              textAlign: "left",
              color: "#000",
              paddingInline: "6%",
              paddingBottom: "18px",
            }}
          >
            Products
          </h1>
          <div className="productFullImage"></div>
        </div>
        <Connect />
        <CaseStudies />
        {/* <CoreValues /> */}

        {/* <div className="service"> */}
        {/* <div className="home-img3">
            <div className="rhombus3">
              <img src={websiteimg2} alt="home-image" />
            </div>
            <div className="rhombus5">
              <img src={websiteimg1} alt="home-image" />
            </div>
            <div className="rhombus4">
              <img src={websiteimg3} alt="home-image" />
            </div>
          </div> */}
        {/* <div className="home-content"> */}
        {/* </div> */}
        {/* </div> */}
        {/* <WeValue /> */}
        {/* <About /> */}
        {/* <Featured /> */}
        {/* <Testimonials /> */}
      </div>
      <Footer />
    </>
  )
}

export default HomePage
